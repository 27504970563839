var render = function render(){var _vm=this,_c=_vm._self._c;return _c('JdsSimpleTable',{staticClass:"!table-auto"},[_c('thead',[_c('tr',[_c('th',{staticClass:"!font-roboto !text-sm",attrs:{"colspan":"2"}},[_vm._v(" Info Detail ")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"min-w-[228px] font-lato text-blue-gray-500 font-bold text-sm"},[_vm._v(" Judul ")]),_c('td',{staticClass:"w-full font-lato text-blue-gray-500 text-sm"},[(_vm.loading)?_c('div',{staticClass:"h-4 w-1/4 rounded-lg animate-pulse bg-gray-200"}):_c('div',[_vm._v(" "+_vm._s(_vm.title)+" ")])])]),_c('tr',[_c('td',{staticClass:"min-w-[228px] font-lato text-blue-gray-500 font-bold text-sm"},[_vm._v(" Judul Button ")]),_c('td',{staticClass:"w-full font-lato text-blue-gray-500 text-sm"},[(_vm.loading)?_c('div',{staticClass:"h-4 w-1/4 rounded-lg animate-pulse bg-gray-200"}):_c('div',[_vm._v(" "+_vm._s(_vm.titleButton)+" ")])])]),_c('tr',[_c('td',{staticClass:"min-w-[228px] font-lato text-blue-gray-500 font-bold text-sm"},[_vm._v(" Link Redirect ")]),_c('td',{staticClass:"w-full font-lato text-blue-gray-500 text-sm"},[(_vm.loading)?_c('div',{staticClass:"h-4 w-1/4 rounded-lg animate-pulse bg-gray-200"}):_c('a',{staticClass:"font-lato text-[12px] leading-[23px]",class:{
            'underline text-blue-gray-500 font-bold' : _vm.link !== '-',
            'pointer-events-none' : _vm.link === '-'
          },attrs:{"href":_vm.link === '-' ? '' : _vm.link,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.link)+" ")])])]),_c('tr',[_c('td',{staticClass:"min-w-[228px] font-lato text-blue-gray-500 font-bold text-[12px] leading-[23px]"},[_vm._v(" Status ")]),_c('td',{staticClass:"w-full"},[(_vm.loading)?_c('div',{staticClass:"h-4 w-1/4 rounded-lg animate-pulse bg-gray-200"}):_c('div',{staticClass:"w-fit font-lato text-[12px] leading-[23px]",class:{
            'bg-green-50 text-green-700 px-[10px] rounded-[5px] font-bold ' : _vm.status === 'Aktif',
            'bg-gray-200 text-gray-600 px-[10px] rounded-[5px] font-bold ' : _vm.status === 'Tidak Aktif'
          }},[_vm._v(" "+_vm._s(_vm.status)+" ")])])]),_c('tr',[_c('td',{staticClass:"min-w-[228px] font-lato text-blue-gray-500 font-bold text-sm"},[_vm._v(" Durasi Penayangan ")]),_c('td',{staticClass:"w-full font-lato text-blue-gray-500 text-sm"},[(_vm.loading)?_c('div',{staticClass:"h-4 w-1/4 rounded-lg animate-pulse bg-gray-200"}):_c('div',[_vm._v(" "+_vm._s(_vm.duration)+" ")])])]),_c('tr',[_c('td',{staticClass:"min-w-[228px] font-lato text-blue-gray-500 font-bold text-sm"},[_vm._v(" Waktu Penayangan ")]),_c('td',{staticClass:"w-full font-lato text-blue-gray-500 text-sm"},[(_vm.loading)?_c('div',{staticClass:"h-4 w-1/5 rounded-lg animate-pulse bg-gray-200"}):_c('div',[_vm._v(" "+_vm._s(_vm.broadcastTime)+" ")])])]),_c('tr',[_c('td',{staticClass:"font-lato text-blue-gray-500 font-bold text-sm"},[_vm._v(" Update Terakhir ")]),_c('td',{staticClass:"font-lato text-blue-gray-500 text-sm"},[(_vm.loading)?_c('div',{staticClass:"h-4 w-1/4 rounded-lg animate-pulse bg-gray-200"}):_c('div',[_vm._v(" "+_vm._s(_vm.lastUpdate)+" ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }